import { Typography } from "@mui/material";
import React from "react";

const About = () => (
  <>
    <Typography variant="h4">About Scenic Peaks Counseling</Typography>
    <Typography>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit...
    </Typography>
  </>
);

export default About;
